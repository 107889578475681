@import '../../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--colors-surfaces-primary-1);
  cursor: default;

  padding: 40px 20px;

  > h2 {
    margin-top: 16px;
    font-size: 24px;
    text-align: center;
  }

  > p {
    margin: 16px 0px;
    max-width: 441px;
    text-align: center;
  }

  > svg {
    width: 120px;
    height: 120px;
    fill: var(--colors-text-heading-1);
  }

  @include mobile {
    > svg {
      height: 80px;
      width: 80px;
    }

    > h2 {
      font-size: 20px;
    }
  }
}
