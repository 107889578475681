.container {
  display: flex;
  flex-direction: column;

  hr {
    margin-top: 16px;
    margin-bottom: 16px;
    border: var(--colors-surfaces-primary-1) 1px solid;
  }

  p {
    margin-left: 30px;
  }
}

.heading {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--colors-text-body-5);
    margin-right: 10px;
  }
}
