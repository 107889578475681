.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--colors-button-primary-background-default);

  padding: 0 8px;
  height: 26px;

  span {
    color: var(--colors-button-primary-content);
    font-weight: var(--font-heading-weight);
    text-wrap: nowrap;
  }
}
