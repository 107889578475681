@import '../../theme/styles/mixins';

@mixin slideInLeft($motionReduced: false) {
  @keyframes slideInLeft {
    from {
      opacity: 0;

      @if $motionReduced == false {
        transform: translateX(-110%);
      }
    }
    to {
      opacity: 1;
    }
  }
}

@mixin slideOutLeft($motionReduced: false) {
  @keyframes slideOutLeft {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;

      @if $motionReduced == false {
        transform: translateX(-110%);
      }
    }
  }
}

.animate {
  animation-fill-mode: both;
  animation-duration: 400ms;
}

.slideEnter {
  @include slideInLeft;
  animation-name: slideInLeft;

  @media (prefers-reduced-motion) {
    @include slideInLeft(true);
    animation-name: slideInLeft;
  }
}

.slideExit {
  @include slideOutLeft;
  animation-name: slideOutLeft;

  @media (prefers-reduced-motion) {
    @include slideOutLeft(true);
    animation-name: slideOutLeft;
  }
}

.toast {
  z-index: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: var(--colors-surfaces-primary-0);
  border-radius: 6px;
  box-shadow:
    0 1px 10px 0 getBoxShadowRGBA(0.1),
    0 2px 15px 0 getBoxShadowRGBA(0.05);
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 1rem;
}

.success {
  background-color: var(--colors-components-toast-success);
}

.error {
  background-color: var(--colors-components-toast-error);
}

.info {
  background-color: var(--colors-components-toast-info);
}

.colorStripe {
  width: 10px;
  height: auto;
}

.iconCircle {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 50px;

  width: 24px;
  height: 24px;

  svg {
    width: 12px;
    height: 12px;

    fill: var(--colors-surfaces-primary-0);
  }
}

.toastBody {
  flex: 1;
  display: flex;
  flex-direction: row;

  padding: 12px 40px 12px 15px;

  cursor: pointer;
}

.toastContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 12px;
  width: auto;

  p {
    -moz-user-select: none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
    word-break: break-word;
  }
}

.title {
  font-weight: var(--font-heading-weight);
}

.message {
  line-height: 19px;
  padding-top: 4px;
}

.close {
  z-index: 1;
  position: absolute;
  float: right;
  top: 0;
  right: 0;

  padding: 12px;
  border: 0;
  background-color: transparent;
  color: var(--colors-button-primary-content);
  opacity: 0.5;

  align-items: start;

  svg {
    width: 10.5px;
    height: 10.5px;
  }

  &:hover {
    opacity: 0.75;
  }
}
