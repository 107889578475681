.infoText {
  margin-top: 12px;
}

.timeSlotContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-top: 26px;
  margin-bottom: 30px;
  max-height: 150px;
  overflow: auto;

  button {
    height: 52px;
    width: 100%;
    border-radius: 8px;
  }

  .selected {
    border: 2px solid var(--colors-highlights-0) !important;
  }

  .skeleton {
    height: 52px;
    width: 100%;
    border-radius: 8px;
  }
}

.infoContainer {
  border-top: 0.5px solid var(--colors-borders-secondary);
  padding-top: 18px;

  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 16px;
  }

  .selectedTimeSlot {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
  }
}

.continueButton {
  width: 100%;
  margin-top: 32px;
  height: 42px;
}
