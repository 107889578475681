@import '../../../../theme/styles/mixins';

.button,
.button:focus {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  height: 42px;
  border: none;
  cursor: pointer;

  background-color: var(--colors-components-navBar-button-background-0);

  &::after,
  &:hover {
    background-color: var(--colors-components-navBar-button-background-0);
  }

  svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-right: 8px;
    fill: var(--colors-components-navBar-button-content-accent-0);
  }

  span {
    color: var(--colors-components-navBar-button-content-default-0);

    @include tablet {
      color: var(--colors-button-secondary-content);
    }
  }

  &.outletDetailsPage:not(.hasPreOrderCheckout) {
    @media screen and (min-width: 992px) and (max-width: 1470px) {
      min-width: 42px;

      padding-left: 12px;
      padding-right: 12px;

      > span {
        display: none;
      }

      svg {
        margin-right: 0;
      }
    }
  }

  @include mobile {
    width: 36px;
    height: 36px;
    min-width: 36px;

    padding-left: 12px;
    padding-right: 12px;

    span {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
}
