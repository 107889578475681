@import '../../../theme/styles/mixins';

@include fadeColor(transparent, #00000099);

.modal {
  display: flex;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
}

.backdropFade {
  background-color: #00000099;
  animation: fadeColor 0.15s linear;
}

.modalFadeOut {
  transition: opacity 0.15s linear;
  opacity: 0;
}

@include mobile {
  .bottomSheetModal {
    display: block;
    animation: fadeColor 0.3s linear;
  }

  .bottomSheetSlideInDown {
    transition:
      transform 0.15s ease-in,
      opacity 0.15s linear;
    transform: translateY(50px) !important;
    opacity: 0;
  }

  .fullScreenFadeOut {
    transition: opacity 0.15s linear;
    opacity: 0;
  }
}

.cardSlideInUp {
  transition:
    transform 0.15s ease-in,
    opacity 0.15s linear;
  transform: translateY(-30px);
  opacity: 0;
}

.modalOpen {
  overflow-y: hidden;
}

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
