@import '../../../theme/styles/mixins';

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 30px;

  @include mobile {
    padding: 0 15px;
  }
}

.fluid {
  max-width: 100%;
}

.sm {
  max-width: 540px;
}

.md {
  max-width: 720px;
}

.lg {
  max-width: 960px;
}

.xl {
  max-width: 1140px;
}
