.footer {
  flex: 1;
  position: relative;
  padding: 0px 20px;
}

.footer::after {
  content: '';
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    var(--colors-surfaces-primary-0)
  );
}

.footerTotalRow {
  width: fit-content;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.footerTotalCount {
  background-color: var(--colors-components-badges-default-background);
  height: 32px;
  width: 32px;
  margin-right: 16px;
  border-radius: 50px;
  text-align: center;
  padding-top: 3px;

  p {
    color: var(--colors-components-badges-default-text);
  }
}

.footerTotalBreakdown {
  flex: 1;
}

.footerButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    width: 100%;
    min-height: 36px;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 10px;
    }
  }
}
