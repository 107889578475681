.container {
  flex-direction: row;
  display: flex;
  background-color: var(--colors-surfaces-primary-3);
  border-radius: 8px;
  padding: 8px 10px;
  align-items: center;
}

.warningText {
  color: var(--colors-text-warning-0);
}

.actionButton {
  margin-left: 10px;
  border: 1px solid var(--colors-borders-secondary);
}

.iconWrapper {
  display: flex;
  align-self: baseline;
  padding-top: 5px;

  svg {
    margin-right: 12px;
    fill: var(--colors-text-warning-1);
    height: 24px;
    width: 24px;
  }
}
