@import '../../../../theme//styles/mixins';

.navBrand {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;

  &.whiteLabeled {
    margin: 0 24px;
  }

  img {
    width: 176px;
    object-fit: contain;

    @include mobile {
      width: 130px;
    }
  }

  &.cornerApp {
    img {
      height: 48px;
    }
  }

  &.heatApp {
    img {
      height: 50px;

      @include tablet {
        height: 45px;
      }

      @include smallMobile {
        height: 40px;
      }
    }
  }
}
