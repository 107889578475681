@import '../../../theme/styles/mixins.scss';

.content {
  display: flex;
  flex-direction: row;
  min-height: var(--vh100);
  position: relative;

  // Avoid content being hidden behind the sticky nav bar
  padding-top: 72px;

  @include xxDesktop {
    &.drawerOpen {
      > main {
        max-width: calc(100vw - 420px);
      }
    }
  }
}
