@import '../../theme/styles/mixins';

.accordion {
  padding: 15px 20px;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
  }
}

.accordionHeader {
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    flex-direction: row;
  }

  .subtitle {
    &.error {
      font-weight: var(--font-heading-weight);
      color: var(--colors-text-body-4);
    }
  }
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}

.accordionContent {
  padding-top: 10px;
}

.accordionContentContainer {
  overflow: auto;

  @include hideScrollBar;
}

.caret {
  flex-shrink: 0;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  padding: 4px;
  fill: var(--colors-button-secondary-content);
  background-color: var(--colors-button-secondary-background-default);
}
