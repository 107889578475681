@import '../../../theme/styles/mixins.scss';

.container {
  width: 480px;
  max-width: 480px;
  max-height: calc(var(--vh100) - 100px);
  margin: 16px 0px;

  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  button {
    width: 100%;
    padding: 16px;
  }

  .languageButton {
    margin-top: 16px;
    border-radius: 8px;

    &.selected {
      background: var(--colors-highlights-1);
      border: 2px solid var(--colors-highlights-0);

      ::after {
        background: var(--colors-highlights-1);
      }
    }
  }

  .footer {
    padding-top: 32px;

    > button {
      padding: 20px 24px;
    }
  }
}
