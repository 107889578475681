.alert {
  margin-bottom: 15px;
}

.signUpButton,
.nextButton {
  width: 100%;
  height: 42px;

  margin-top: 32px;
}

.nextButton {
  margin-top: 32px;

  svg {
    width: 22px;
    height: 22px;
    margin-left: 4px;
  }
}

.signUpButton {
  margin-top: 15px;
}

.backButton {
  display: flex;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  svg {
    position: absolute;
    width: 18px;
    height: 18px;
  }
}