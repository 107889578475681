@import '../../theme/styles/mixins.scss';

:root {
  --heading-font-size-h1: 3.2rem;
  --heading-line-height-h1: 2;

  --heading-font-size-h2: 2.4rem;
  --heading-line-height-h2: 2;

  --heading-font-size-h3: 1.6rem;
  --heading-line-height-h3: 1.375;

  --body-font-size: 1.4rem;
  --body-line-height: 1.572;

  /* Theme placeholders below */

  --colors-text-heading-0: #000;
  --colors-text-heading-1: #000;

  --colors-text-body-0: #000;
  --colors-text-body-1: #000;
  --colors-text-body-2: #000;
  --colors-text-body-3: #000;
  --colors-text-body-4: #000;
  --colors-text-body-5: #000;

  --colors-button-primary-background-default: #000;
  --colors-button-primary-background-hover-0: #000;
  --colors-button-primary-content: #fff;

  --colors-button-secondary-background-default: #000;
  --colors-button-secondary-background-hover-0: #000;
  --colors-button-secondary-content: #fff;

  /* 
    Use this css variable instead of vh to prevent mobile browsers from resizing the viewport when the address bar is shown/hidden
    https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    --vh100 value should consider as the new 100vh value
  */
  --vh100: calc(var(--vh, 1vh) * 100);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  max-height: var(--vh100);
  overflow-x: clip;

  background-color: var(--colors-surfaces-body);

  // To prevent resetting scroll position on modal open
  // Opening modal will apply overflow-y: hidden to body
  overflow-y: auto;
}

html {
  font-size: 62.5%;
}

body {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-family: var(--font-body-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: var(--font-heading-weight);
  font-family: var(--font-heading-family);
  color: var(--colors-text-heading-0);
}

h1 {
  font-size: var(--heading-font-size-h1);
  line-height: var(--heading-line-height-h1);
}

h2 {
  font-size: var(--heading-font-size-h2);
  line-height: var(--heading-line-height-h2);
}

h3 {
  font-size: var(--heading-font-size-h3);
  line-height: var(--heading-line-height-h3);
}

/* These are not yet used */
/* h4 {
}

h5 {
}

h6 {
} */

p,
span {
  font-weight: var(--font-body-weight);
  font-family: var(--font-body-family);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

a {
  color: inherit;
  text-decoration: none;
}

// Scroll bar theming
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px getBoxShadowRGBA(0.3);
  background-color: #ffffff;
  border-radius: 12px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #0000006e;
  border-radius: 20px;
}
