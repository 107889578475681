@import '../../../theme/styles/mixins';

.modalFooter {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;

  @include mobile {
    flex-direction: column;
    gap: 10px;
  }

  button {
    flex: 1;
    width: 100%;
  }
}

.alert {
  margin-bottom: 15px;
}