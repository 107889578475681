@import '../../../theme/styles/mixins';

@keyframes slideInUp {
  from {
    bottom: -50px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.modalCard {
  position: absolute;
  bottom: 0;
  border-radius: 0;
  animation: slideInUp 0.3s ease-out;
  padding: 0;
  width: 100%;

  @include mobile {
    width: 100%;
  }
}

.backdrop {
  top: 72px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #00000066;
}
