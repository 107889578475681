@import '../../theme/styles/mixins';

.modal > div {
  max-width: 480px;

  @include mobile {
    max-width: 100%;
  }

  h2 {
    margin-right: 10px;
    line-height: 36px;
    margin-bottom: 16px;
  }
}
