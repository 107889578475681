@import '../../theme/styles/mixins';

.modalContent {
  max-width: 480px;

  h2 {
    margin-right: 10px;
  }
}

.modalBody {
  margin-top: 20px;
  margin-bottom: 40px;
}

.modalFooter {
  flex-direction: row;
  display: flex;
  gap: 20px;

  @include smallMobile {
    flex-direction: column;
    gap: 12px;
  }

  > button {
    flex: 1;
    width: 100%;
  }
}
