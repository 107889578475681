@import '../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;

  width: 520px;
  max-height: calc(var(--vh100) - 20px);

  @media only screen and (min-height: 900px) {
    max-height: 900px;
  }

  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  border-radius: 12px;
  overflow: hidden;
}

.productImage {
  width: 100%;
  height: 240px;
  min-height: 240px;
}

.metaContainer {
  padding: 20px 20px 16px 20px;
  border-bottom: var(--colors-surfaces-primary-1) 1px solid;
}

.productName {
  width: 255px;
  height: 30px;
  margin-bottom: 8px;
  border-radius: 20px;
}

.priceLabel {
  width: 80px;
  height: 20px;
  border-radius: 20px;
}

.description {
  margin-top: 16px;
  height: 26px;
  border-radius: 20px;
}

.modificationsContainer {
  overflow: auto;

  @include mobile {
    margin-bottom: 125px;
  }
}

.variantContainer {
  padding: 20px 20px 16px 20px;
  display: flex;
  flex-direction: column;
}

.variantTitle {
  width: 230px;
  height: 22px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.variantItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0px 10px 0px;
}

.variantNameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.variantSelection {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  margin-right: 18px;
}

.variantName {
  width: 140px;
  height: 20px;
  margin-bottom: 8px;
  border-radius: 20px;
}

.variantPrice {
  width: 100px;
  height: 20px;
  border-radius: 20px;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  padding: 15px 20px;
  background: var(--colors-surfaces-primary-0);
  box-shadow: 0px -2px 10px getBoxShadowRGBA(0.1);

  @include mobile {
    flex-direction: column;
    position: absolute;
    width: 100%;
    align-items: center;
  }
}

.quantityModifier {
  width: 135px;
  height: 40px;
  border-radius: 20px;
}

.addToCart {
  width: 240px;
  height: 45px;
  border-radius: 1000px;

  @include mobile {
    margin-top: 10px;
    width: 100%;
  }
}
