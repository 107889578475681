@import '../../theme/styles/mixins';

.modal > div {
  max-width: 520px;

  @include mobile {
    max-width: 100%;
  }

  h2 {
    margin-right: 10px;
    line-height: 36px;
  }
}

.modalBody {
  margin-top: 30px;
  margin-bottom: 40px;
}

.modalFooter {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  button {
    flex: 1;
  }
}
