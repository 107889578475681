.container {
  display: table;
  border-radius: 4px;
  background-color: var(--colors-button-primary-background-default);

  h3 {
    padding: 4px;
    display: table-cell;
    font-weight: var(--font-heading-weight);
    color: var(--colors-button-primary-content);
  }
}
