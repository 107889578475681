@import '../../../theme/styles/mixins';

.modalBody {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 520px;

  @include mobile {
    width: 100%;
    max-width: 100%;
  }
}

.resultSection {
  padding: 0 15px;
  margin: 15px 0;
  overflow: auto;
  flex-grow: 1;
  height: 200px;

  @include mobile {
    height: unset;
    padding: 0 0px;
    overflow: hidden;
  }
}

.modal > div {
  display: flex;
  flex-direction: column;

  > div:first-of-type {
    @include mobile {
      display: none;
    }
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
}

.searchInput {
  flex: 1;
}

.backButton {
  display: flex;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  border: none;
  display: none;

  svg {
    width: 20px;
    height: 20px;
  }

  @include mobile {
    display: flex;
  }
}
