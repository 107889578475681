@import '../../../theme/styles/mixins.scss';

.button {
  &:hover {
    cursor: pointer;
  }

  p {
    font-weight: var(--font-heading-weight);
    text-decoration: underline;
    text-wrap: nowrap;
  }

  @include smallMobile {
    margin-bottom: 5px;
  }
}
