@import '../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;

  width: 520px;
  max-height: calc(var(--vh100) - 20px);

  @media only screen and (min-height: 900px) {
    max-height: 900px;
  }

  @include mobile {
    width: 100%;
    max-height: var(--vh100);
    padding-bottom: 130px;
  }

  // Close Button
  > button {
    z-index: 2;
    top: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
    position: absolute;
    padding: 5px;
  }
}
