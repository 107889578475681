.container {
  h3 {
    font-weight: var(--font-heading-weight);
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .webLink {
    p {
      color: -webkit-link;
      text-decoration: underline;
    }
  }
}
