.button {
  padding: 5px 12px;

  span {
    font-size: 1.4rem;
    line-height: 1.572;
    color: var(--colors-text-body-0);
  }

  span > svg {
    fill: var(--colors-text-body-2);
  }
}
