.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: var(--colors-button-secondary-background-default);

  padding: 0 8px;
  height: 26px;

  svg,
  span {
    color: var(--colors-button-secondary-content);
  }

  svg {
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }

  span {
    font-weight: var(--font-heading-weight);
  }
}
