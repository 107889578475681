.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: solid 1px var(--colors-surfaces-primary-1);
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.priceText {
  width: 65px;
  text-align: right;
}

.quantityModifier {
  button {
    z-index: 0;
  }
}
