.container {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  color: var(--colors-text-body-0);
  margin: 0 -15px;

  span {
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--colors-text-body-1);
    height: 5px;
    width: 5px;
    min-width: 5px;
    border-radius: 50%;
  }

  p {
    color: var(--colors-text-body-1);
  }
}

.leftAccessory {
  display: flex;

  // Spinner
  > div {
    margin-right: 15px;
  }

  svg {
    margin-right: 15px;
    width: 15px;
    height: 15px;
    fill: var(--colors-text-body-2);
  }
}

.container:hover {
  background-color: var(--colors-button-secondary-background-hover-1);
}
