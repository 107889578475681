.sectionContainer {
  margin-top: 15px;

  h3 {
    font-size: 12px;
    color: var(--colors-text-body-1);
  }
}

.loaderContainer {
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;

  p {
    margin-left: 15px;
  }
}

.emptyContainer {
  padding: 10px 0px;
}
