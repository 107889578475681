.container {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 8px;

  span {
    margin-right: 5px;
    color: var(--colors-text-body-0);
  }

  a {
    p {
      font-weight: var(--font-heading-weight);
      text-decoration: underline;
    }
  }
}

.checkbox {
  margin-left: 0;
  margin-right: 16px;
}
