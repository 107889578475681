.container {
  height: var(--vh100);
  width: 100vw;
}

.footerActions {
  display: flex;

  margin-top: 10px;

  button:first-child {
    margin-right: 10px;
  }
}
