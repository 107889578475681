@import '../../../theme/styles/mixins';

.container {
  border-radius: 12px;
  background-color: var(--colors-surfaces-primary-0);
  flex-shrink: 1;
  padding: 15px;

  box-shadow: 0px 3px 20px getBoxShadowRGBA();
}
