@import '../../theme/styles/mixins';

.modalBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 24px;
  padding-bottom: 16px;
  width: 400px;

  @include mobile {
    max-width: 100%;
    width: 100%;
  }

  > div {
    grid-template-columns: repeat(2, 1fr);

    button {
      height: 150px;

      @include mobile {
        height: 85px;
      }
    }
  }
}
