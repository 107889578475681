.closeButton {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--colors-button-secondary-background-default);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 36px;
  align-self: baseline;

  &:hover,
  &:focus {
    filter: brightness(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--colors-button-secondary-content);
  }
}
