@import '../../theme/styles/mixins';

.button {
  display: flex;

  svg {
    align-self: center;
    height: 20px;
    width: 20px;
    fill: var(--colors-text-body-1);
    cursor: pointer;

    &:hover,
    &:focus {
      fill: var(--colors-text-body-1);
    }
  }
}
