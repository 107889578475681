@import '../../../theme/styles/mixins';

.row {
  display: flex;
  flex-direction: row;
}

.spaceBetween {
  justify-content: space-between;
  gap: 12px;
}

.titleRow {
  margin: 10px 0;
}

.outOfStockTag {
  padding: 5px;
  background-color: rgba(214, 24, 42, 0.1);
  border-radius: 4px;

  p {
    color: var(--colors-text-danger);
    font-weight: var(--font-heading-weight);
  }
}

.radioButton {
  margin-left: 0 !important;
  margin-right: 16px;
}

.optionRow {
  margin: 6px 0;
  align-items: center;
}

.warningText {
  color: var(--colors-text-warning-2);
  text-align: center;
}

.singleLineText {
  white-space: nowrap;
}

.productName {
  @include textEllipsis();
}