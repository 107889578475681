@import '../../../../theme/styles/mixins';

.container {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-start;
  height: 42px;
  min-width: 200px;
  border: none;
  align-items: center;
  cursor: pointer;
  max-width: 360px;

  &::after,
  &:hover::after,
  &:focus::after {
    background-color: var(
      --colors-components-navBar-button-background-0
    ) !important;
  }

  @include tablet {
    max-width: 100%;

    &::after,
    &:hover::after,
    &:focus::after {
      background-color: var(
        --colors-button-secondary-background-default
      ) !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-right: 8px;
  }

  span {
    @include textEllipsis(1);
    color: var(--colors-components-navBar-button-content-default-0);
    max-width: 170px;
    word-break: break-all;

    @include tablet {
      max-width: none;
      color: var(--colors-button-secondary-content);
    }
  }

  &:not(.navBarSearch) {
    display: none;

    @include tablet {
      display: flex;
      width: 100%;
      margin-bottom: 0px;
      max-width: calc(100vw - 30px);
    }
  }

  &.navBarSearch {
    flex: 1;

    svg {
      fill: var(--colors-components-navBar-button-content-accent-0);
    }

    &.outletDetailsPage:not(.hasPreOrderCheckout) {
      @media screen and (min-width: 992px) and (max-width: 1368px) {
        min-width: 42px;
        justify-content: center;

        > span {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }

      &.whiteLabeled {
        @media screen and (min-width: 1368px) and (max-width: 1470px) {
          min-width: 42px;
          justify-content: center;

          > span {
            display: none;
          }

          svg {
            margin-right: 0;
          }
        }
      }
    }

    @include tablet {
      display: none;
    }
  }

  .spinner {
    margin-right: 8px;
  }
}
