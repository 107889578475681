@import '../../theme/styles/mixins';

.modal > div {
  max-width: 420px;

  h2 {
    margin-right: 10px;
  }
}

.modalBody {
  margin-top: 20px;
  margin-bottom: 40px;
}

.modalFooter {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include mobile {
    flex-direction: column;
    gap: 10px;
  }

  button {
    flex: 1;
    width: 100%;
  }
}