@import '../../../theme/styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 5px;
  border-radius: 4px;

  p {
    font-weight: var(--font-heading-weight);
    font-size: 1.3rem;
    text-wrap: nowrap;
  }

  svg {
    height: 14px;
    width: 14px;
    padding: 3px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &.ready {
    p {
      color: var(--colors-components-tags-itemStatus-ready-background);
    }

    svg {
      fill: var(--colors-components-tags-itemStatus-ready-text);
      background-color: var(
        --colors-components-tags-itemStatus-ready-background
      );
    }
  }

  &.pickedUp {
    p {
      color: var(--colors-components-tags-itemStatus-pickedUp-text-1);
    }

    svg {
      fill: var(--colors-components-tags-itemStatus-pickedUp-text-0);
      background-color: var(
        --colors-components-tags-itemStatus-pickedUp-background
      );
    }
  }
}
