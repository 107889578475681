@import '../../../theme/styles/mixins';

.headerContainer {
  padding: 0px 10px;
}

.headerInnerContainer {
  padding: 0px 10px 10px;
  border-bottom: 0.5px solid var(--colors-text-body-2);
}

.headerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.headerTopTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  h2 {
    line-height: 1.714;
  }
}

.headerTopLoader {
  margin-left: 10px;
}

.headerTopActions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-top: 4px;
}

.headerCloseButton {
  height: 36px;
  width: 36px;
  padding: 5px;

  @include xxDesktop {
    display: none;
  }
}

.headerInformation {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.headerInformationTags {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  gap: 16px;
}

.orderFormat {
  svg {
    height: 12px;
    width: 14px;
    margin-right: 4px;
    fill: var(--colors-text-body-1);
  }

  span {
    color: var(--colors-text-body-4);
    font-weight: var(--font-heading-weight);
  }
}

.serviceTime {
  display: inline-flex;
  align-items: center;
}

.middot {
  margin-left: 5px;
  margin-right: 5px;
  line-height: 22px;
}
