.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
}

.title {
  margin-bottom: 20px;
}

.productListWrapper {
  max-height: calc(var(--vh100) - 200px);
  overflow: auto;
}

.productItem {
  padding: 12px 8px;
  border-top: 1px solid var(--colors-borders-secondary);
}
