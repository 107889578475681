@import '../../theme/styles/mixins.scss';

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &.inline {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    p {
      text-align: right;
    }
  }

  span {
    line-height: 24px;
  }

  p {
    font-weight: var(--font-heading-weight);
    line-height: normal;
  }
}
