@import '../../../../theme/styles/mixins.scss';

@keyframes wobbleBottom {
  0%,
  100% {
    transform: translateX(0%) rotate(0) scale(1);
  }

  5% {
    transform: scale(1.5);
  }

  15% {
    transform: rotate(-10deg) scale(1.5);
  }

  20% {
    transform: rotate(8deg) scale(1.5);
  }

  30% {
    transform: rotate(-7deg) scale(1.5);
  }

  45% {
    transform: rotate(5deg) scale(1.5);
  }

  65% {
    transform: rotate(0) scale(1);
  }
}

.cartButton {
  margin-right: 15px;

  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: var(--body-font-size);
    width: var(--body-font-size);
    margin-right: 10px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    margin-right: 5px;
    @include tablet {
      display: none;
    }
  }

  &.cartOpen {
    border: 2px solid var(--colors-button-primary-content);

    svg {
      fill: var(--colors-button-primary-content);
    }
  }

  @include mobile {
    margin-right: 0;
  }
}

.wobbleBottom {
  animation: wobbleBottom 2s ease 0s 1;
}
