.button {
  width: 100%;
  margin-top: 15px;
}

.buttonText {
  font-weight: var(--font-heading-weight);

  &:not(.secondary) {
    color: var(--colors-button-primary-content-hover);
  }
}
