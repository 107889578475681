@import '../../theme/styles/mixins.scss';

.modal {
  > div {
    padding: 0px;
    overflow: hidden;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
}
