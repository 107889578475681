@import '../../theme/styles/mixins';

.modalBody {
  width: 400px;

  @include mobile {
    width: 100%;
  }
}

.modalFooter {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  @include mobile {
    margin-top: 40px;
  }

  button:nth-child(2) {
    min-width: 195px;
  }

  button:first-child {
    margin-right: 10px;

    @include mobile {
      margin-right: 0px;
    }
  }

  @include mobile {
    flex-direction: column;

    button {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
