@import '../../theme/styles/mixins.scss';

.toastsContainer {
  position: fixed;
  z-index: 999;
  width: 350px;
  box-sizing: border-box;

  top: 72px;
  left: 10px;

  padding-top: 10px;

  @include mobile {
    width: 100%;
    padding: 8px;
    left: 0;
  }
}
