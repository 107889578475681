@import '../../theme/styles/mixins';

.modalContent {
  width: 480px;

  @include mobile {
    width: 100%;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 10px 0;

  overflow-y: auto;
  max-height: calc(var(--vh100) * 0.65);

  h2 {
    margin-bottom: 10px;
  }
}

.hostInformation {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  border-radius: 12px;
  background-color: var(--colors-surfaces-primary-4);
}
