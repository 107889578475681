@import '../../../theme/styles/mixins.scss';

.modalContent {
  padding: 0;
}

.modalHeader {
  padding: 20px 20px 10px;
  border-bottom: 0.5px solid var(--colors-borders-secondary);

  h2 {
    font-size: 2.2rem;
    text-transform: uppercase;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 600px;
  padding: 20px;

  max-height: calc(var(--vh100) * 0.65);
  overflow-y: auto;

  @include mobile {
    width: 100%;
  }
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 15px 20px 20px;
  border-top: 0.5px solid var(--colors-borders-secondary);

  button {
    width: 200px;

    @include mobile {
      width: 100%;
    }
  }
}
