.container {
  width: 100%;
  position: relative;
  box-shadow: none;
  border: 2px solid var(--colors-surfaces-primary-1);
  cursor: pointer;

  &.selected {
    border: 2px solid var(--colors-highlights-0);
  }

  &.disabled {
    cursor: not-allowed;
  }

  > img {
    width: 100%;
    object-fit: cover;
    height: 130px;
    border-radius: 12px;

    &.disabled {
      opacity: 0.5;
    }
  }

  > svg {
    width: 100%;
    height: 130px;
    border-radius: 12px;
    fill: var(--colors-text-body-4);
  }
}

.bottomContainer {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  > div {
    margin-top: 10px;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.quantityPicker {
  display: flex;
  align-items: center;
  justify-content: center;
}

.outOfStock {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 5px 10px;
  border-radius: 10px;
  opacity: 1;

  background-color: var(--colors-surfaces-primary-0);

  p {
    font-weight: var(--font-heading-weight);
  }
}
