.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.phoneInput {
  height: 44px;
}

.valid {
  border-color: var(--colors-borders-secondary);

  margin-bottom: 18px;
}

.invalid {
  border-width: 1.5px;
  border-color: var(--colors-text-danger);

  &[class*='PhoneInput--focus'] {
    border-color: var(--colors-text-danger);
  }
}

.label {
  margin-bottom: 6px;

  font-weight: var(--font-heading-weight) !important;
  font-size: 1.25rem !important;

  span {
    line-height: normal;
  }
}
