@import '../../../theme/styles/mixins.scss';

.separator {
  border: none;
  height: 1px;
  background-color: var(--colors-borders-secondary);
  margin: 16px 0;
}

.titleRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  padding-bottom: 32px;
  margin: 20px 20px 0 20px;
}

.closeButton {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: var(--colors-surfaces-primary-4);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 36px;
  align-self: baseline;
  padding: 10px;

  &:hover,
  &:focus {
    filter: brightness(0.95);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--colors-text-heading-1);
  }
}

.itemsWrapper {
  max-height: calc(var(--vh100) - 200px);
  overflow: auto;
  padding: 0 20px;
}

.inCartDrawer {
  max-height: calc(viewHeightUnits(85) - 250px);
}
